export const FirebaseConfig = {
	"projectId": "afritrak-ac2bf",
	"appId": "1:264319157624:web:0a06c9f7e6183dea1df48d",
	"databaseURL": "https://afritrak-ac2bf.firebaseio.com",
	"storageBucket": "afritrak-ac2bf.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyD6qZVl_hYXUGRd6ldrnrGihZ5iYPvY43Q",
	"authDomain": "afritrak-ac2bf.firebaseapp.com",
	"messagingSenderId": "264319157624",
	"measurementId": "G-PP4N2VF6MK"
};